import styled from "styled-components";

export const Button = styled.button`
  border-radius: 5px;
  background: ${({ primary }) => (primary ? "rgb(7, 187, 207)" : "#00FFFF")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: black;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "17px")};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 8px 15px rgba(7, 187, 207, 0.3) !important;
`;